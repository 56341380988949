<template>
  <div class='pb-8 px-16'>
    <div class='flex flex-row my-6'>
      <h1 class='text-3xl font-semibold tracking-wide mr-2'>보유내역</h1>
    </div>
    <div class='flex flex-row my-6'>
      <el-date-picker
        v-model='date'
        style='width: 20rem;'
        type='daterange'
        placeholder='거래일자'
        :default-value='date'
        format='yyyy-MM-dd'
        @change='getPeriodPositions'
        range-separator='~'
        start-placeholder='Start Date'
        end-placeholder='End Date'
        align='right'
        unlink-panels
        :picker-options='pickerOptions'>
      </el-date-picker>
      <input class='form-input ml-2' type='text' placeholder='회사코드/투자명칭' v-model='searchEntities' @keyup.enter='getPeriodPositions' />
      <input class='form-input ml-2' type='text' placeholder='종목코드/종목명' v-model='searchListing' @keyup.enter='getPeriodPositions' />
    </div>
    <div class='overflow-x-scroll  mt-5'>
      <table class=''>
        <thead>
          <tr class='text-left uppercase tracking-wide bposition-b'>
            <th class='px-2 py-2 text-center border-r border-l border-b border-t' colspan='6'>기본정보</th>
            <th class='px-2 py-2 text-center border-r border-b border-t' colspan='3'>투자원금(투자자산)</th>
            <th class='px-2 py-2 text-center border-r border-b border-t' colspan='3'>현재원금(투자자산)</th>
            <th class='px-2 py-2 text-center border-r border-b border-t' colspan='3'>현재원금(기초자산)</th>
            <th class='px-2 py-2 text-center border-r border-b border-t' colspan='3'>회수금</th>
          </tr>
          <tr class='font-semibold uppercase tracking-wide border-b'>
            <table-header-filter-text
              v-bind:filter-text.sync='filterCreatedOn'
              defaultLabel='기준일자'
              class='px-2 py-2 text-left border-l border-r' />
            <th class='px-2 py-2 text-left border-r'>투자번호</th>
            <th class='px-2 py-2 text-left border-r'>투자일</th>
            <table-header-filter-text
              v-bind:filter-text.sync='filterFund'
              defaultLabel='펀드'
              class='px-2 py-2 text-left border-r' />
            <table-header-filter-text
              v-bind:filter-text.sync='filterShareCode'
              defaultLabel='종목코드'
              class='px-2 py-2 text-left border-r' />
            <table-header-filter-text
              v-bind:filter-text.sync='filterName'
              defaultLabel='종목명'
              class='px-2 py-2 text-left border-r' />
            <th class='px-2 py-2 text-right border-r'>투자수량</th>
            <th class='px-2 py-2 text-right border-r'>투자단가</th>
            <th class='px-2 py-2 text-right border-r'>투자금액</th>
            <th class='px-2 py-2 text-left border-r bg-gray-200 text-gray-900'>현재수량</th>
            <th class='px-2 py-2 text-left border-r bg-gray-200 text-gray-900'>현재단가</th>
            <th class='px-2 py-2 text-left border-r bg-gray-200 text-gray-900'>현재금액</th>
            <th class='px-2 py-2 text-left border-r'>기초수량</th>
            <th class='px-2 py-2 text-left border-r'>기초단가</th>
            <th class='px-2 py-2 text-left border-r'>기초금액</th>
            <th class='px-2 py-2 text-left border-r'>회수금</th>
            <th class='px-2 py-2 text-left border-r'>회수금원금</th>
            <th class='px-2 py-2 text-left border-r'>손익</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='position in filteredData'
            :key='`position-${position.id}`'
            class='uppercase tracking-wide border-t border-b'>
            <td class='px-2 py-2 text-left border-l border-r'>{{ position.created_on }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ position.investment_id }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ position.traded_on }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ position.short_name }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ position.share_code }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ position.name }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.investment_quantity) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.investment_price) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.investment_value) }}</td>
            <td class='px-2 py-2 text-right border-r bg-gray-200 text-gray-900'>{{ numberStyle(position.quantity) }}</td>
            <td class='px-2 py-2 text-right border-r bg-gray-200 text-gray-900'>{{ numberStyle(position.average_price) }}</td>
            <td class='px-2 py-2 text-right border-r bg-gray-200 text-gray-900'>{{ numberStyle(position.acquired_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.base_quantity) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.base_price) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.base_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.collection_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.original_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(realizedGain(position)) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import StringHelpers               from '@/utils/string-helpers'
import TableHeaderFilterText       from '@/components/TableHeaderFilterText.vue'
import PositionsApi                from '@/api/v1/positions'
import dayjs from 'dayjs'

export default {
  name: 'InvestmentPositions',
  components: {
    TableHeaderFilterText,
  },
  data () {
    return {
      date: [dayjs(), dayjs()],
      pickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      positions: [],
      searchEntities: '',
      searchListing: '',
      filterCreatedOn: '',
      filterFund: '',
      filterShareCode: '',
      filterName: '',
      filterTradeType: '',
    }
  },
  computed: {
    filteredData () {
      if (this.filterCreatedOn === '' &&
        this.filterFund === '' &&
        this.filterShareCode === '' &&
        this.filterName === '' &&
        this.filterTradeType === '') {
        return this.positions
      } else {
        return this.positions.filter(data =>
          (this.filterCreatedOn === ''    || StringHelpers.stringIncludesInsensitive(data.created_on, this.filterCreatedOn)) &&
          (this.filterFund === ''    || StringHelpers.stringIncludesInsensitive(data.fund_name, this.filterFund)) &&
          (this.filterShareCode === '' || StringHelpers.stringIncludesInsensitive(data.share_code, this.filterShareCode)) &&
          (this.filterName === ''   || StringHelpers.stringIncludesInsensitive(data.name, this.filterName)) &&
          (this.filterTradeType === ''   || StringHelpers.stringIncludesInsensitive(data.trade_type, this.filterTradeType))
        )
      }
    },
  },
  methods: {
    initialEntityName () {
      return this.useComponent ? '00000' : ''
    },
    getPeriodPositions () {
      PositionsApi.getPeriodPositions({
                    start_date: dayjs(this.date[0]).format('YYYYMMDD'),
                    end_date: dayjs(this.date[1]).format('YYYYMMDD'),
                    search_entities: this.searchEntities,
                    search_listing: this.searchListing
                   }).then(resp => {
        this.positions = resp
      })
    },
    numberStyle (number) {
      return number ? Number(number).toLocaleString() : 0
    },
    realizedGain (position) {
      return (position.collection_value - position.original_value)
    },
  },
  mounted () {
    this.getPeriodPositions()
  },
}
</script>
